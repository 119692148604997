<template>
  <div class="container text-center">
    <img class="img-fluid " alt="JumpWorld Logo" src="../assets/jwb_banner.png"/>
    <div class="container mt-5">
      <h4>Herzlich Willkommen im JumpWorld-Browser.</h4>
      <h4>Auf dieser Webseite hast du die Möglichkeit, nach <router-link to="search">JnRs zu suchen</router-link>, ihre Topzeiten und Bewertungen einzusehen und, solltest du auch ingame online sein, durch anklicken des "Beitreten"-Buttons direkt in das JnR joinen.</h4>
      <br>
      <h4>Du bist komplett neu auf JumpWorld?</h4>
      <h4>Dann solltest du direkt das <router-link to="tutorial">Tutorial</router-link> anschauen, danach bist du der absolute JumpWorld-Profi.</h4>
      <h4>Zudem findest du auf dieser Webseite alle wichtigen Befehle, aufgeteilt in <router-link to="/commands/jumpworld">allgemeine Befehle</router-link>
        und <router-link to="/commands/worldedit">WorldEdit-Befehle</router-link>. Schau einfach mal rein, du wirst sicherlich hilfreiche Befehle entdecken!</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home'
};
</script>

<style scoped>
a {
  color: orange;
}

a:hover {
  color: #ff8000;
}
</style>
